<template>
  <div class="flex h-full w-full flex-col mx-12">
    <p class="font-bold text-15">Market Price</p>
    <table>
      <tr>
        <th />
        <th class="text-right pr-8">Per Part</th>
        <th class="text-right pr-8">Per Lot</th>
      </tr>
      <tr>
        <td class="text-13 py-8">Cost</td>
        <td class="text-right pr-8">
          {{ $formatPrice(amCostPerPart) }}
        </td>
        <td class="text-right pr-8">
          {{ $formatPrice(amCostPerLot) }}
        </td>
      </tr>
      <tr>
        <td class="text-13 py-8">Gross Margin</td>
        <td colspan="2" class="text-center">{{ grossMargin }}%</td>
      </tr>
      <tr>
        <td class="text-13 py-8">Pricing Config</td>
        <td colspan="2" class="text-center">{{ usedPricingConfig.name }}</td>
      </tr>
      <tr>
        <td class="text-15">Price</td>
        <td class="text-center">
          <FeedbackValue
            :feedback-value="marketPricePerPart"
            no-heading
            field-name=""
            field-unit="€"
            field-type="validator"
            @feedback-value-changed="setMarketPricePerPartUserValue($event, 'market_price_pp')"
          />
        </td>
        <td class="text-center">
          <FeedbackValue
            :feedback-value="marketPricePerLot"
            no-heading
            field-name=""
            field-unit="€"
            field-type="validator"
            @feedback-value-changed="setMarketPricePerPartUserValue($event, 'market_price_pl')"
          />
        </td>
      </tr>
    </table>

    <!--
 <div class="flex gap-x-80 mt-48 mb-8">
      <div class="text-13 mr-8 font-bold">Gross Margin</div>
      <div class="text-11">{{ grossMargin }}%</div>
    </div>
    <div class="flex gap-x-80 mb-48">
      <div class="text-13 font-bold">Pricing Config</div>
      <div class="text-11">{{ usedPricingConfig.name }}</div>
    </div> 
-->
    <p class="font-bold text-15 mt-20">External Market Price</p>

    <SparkButton
      title="Request market price from manufacturer website using representive part. No actual CAD data will be sent."
      variant="outlined"
      :disabled="buttonDisabled"
      @click="requestMarketPrice"
    >
      <div class="text-13" :class="{ flex: loading }">
        <img v-if="loading" src="@/assets/img/loading.gif" class="h-16" />{{ buttonTitle }}
      </div>
    </SparkButton>
    <ExternalMarketPrice />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import SparkButton from '../../SparkComponents/SparkButton.vue';

import ExternalMarketPrice from '@/components/PartWorkflow/Cost/ExternalMarketPrice.vue';
import FeedbackValue from '@/components/Reusable/FeedbackValues/FeedbackValue.vue';
import useNumberFormatting from '@/composables/numberFormatting.js';

export default {
  name: 'MarketPrice',

  components: {
    FeedbackValue,
    SparkButton,
    ExternalMarketPrice,
  },

  props: {
    chain: { type: Object, required: true },
  },

  data() {
    return {
      currentMargin: 20,
      buttonTitle: 'Request External Market Prices',
      loading: false,
      anotherRequest: false,
      usedPricingConfig: {},
    };
  },

  computed: {
    ...mapState(['user', 'actualMarketPriceRequest', 'investigationDetails', 'pricingOptions']),
    ...mapState('application', ['axiosInstance']),
    ...mapState({ storePart: 'part' }),
    ...mapGetters(['processChainsInitializationFinished']),

    processChainId() {
      return this.chain.process_chain_id;
    },

    checkVolume() {
      if (this.part.part_vol <= (this.part.bb_x * this.part.bb_y * this.part.bb_z) / 1000) {
        return false;
      } else {
        return true;
      }
    },

    checkVolumeRatio() {
      if ((this.part.part_vol * 1000) / (this.part.bb_x * this.part.bb_y * this.part.bb_z) >= 1 / 1000) {
        return false;
      } else {
        return true;
      }
    },

    buttonDisabled() {
      if (this.loading || this.hasError) {
        return true;
      }
      if (this.anotherRequest) {
        return true;
      } else {
        return false;
      }
    },

    hasError() {
      if (this.checkVolume || this.checkVolumeRatio) {
        return true;
      } else {
        return false;
      }
    },

    part: {
      get() {
        return this.storePart;
      },

      set(value) {
        this.updatePart(value);
      },
    },

    boolProcessChainExists() {
      if (this.chain == null) {
        return false;
      }
      if (this.chain.cost != null) {
        return true;
      } else {
        return false;
      }
    },

    marketPricePerPart() {
      if (this.boolProcessChainExists) {
        return this.chain.market_price.market_price_pp;
      } else return {};
    },

    marketPricePerLot() {
      if (this.boolProcessChainExists) {
        return this.chain.market_price.market_price_pl;
      } else return {};
    },

    grossMargin() {
      const pricePerPart = this.marketPricePerPart.user
        ? this.marketPricePerPart.user
        : this.marketPricePerPart.calculated;

      const margin = (pricePerPart / this.amCostPerPart - 1) * 100;
      return Math.round(margin);
    },

    costingConfig() {
      let costingConfig = {};
      if (this.user.organization != undefined && this.user.organization.costing_configs != undefined) {
        if (this.chain != undefined) {
          for (const [key, value] of Object.entries(this.user.organization.costing_configs)) {
            if (key == this.chain.costing_config_id) {
              costingConfig = value;
            }
          }
        }
        // ! correct handling of costingConfig has to be checked in the template section !
      }
      return costingConfig;
    },

    amCostPerPart() {
      if (this.boolProcessChainExists) {
        return this.chain.cost.cost_pp;
      }
      return 0;
    },

    amCostPerLot() {
      if (this.boolProcessChainExists) {
        return this.chain.cost.cost_pl;
      }
      return 0;
    },
  },

  watch: {
    costingConfig: {
      handler() {
        this.updateMargin();
      },

      immediate: true,
    },

    actualMarketPriceRequest: {
      handler(newVal) {
        // market Price Request for actual Part
        if (newVal === this.part.part_id) {
          this.anotherRequest = false;
          this.loading = true;
        }
        // market Price request for another part
        if (newVal != '' && newVal != this.part.part_id) {
          this.anotherRequest = true;
          this.loading = false;
        }
        // no actual Market Price Request
        if (newVal === '') {
          this.anotherRequest = false;
          this.loading = false;
          this.buttonTitle = 'Request External Market Prices';
        }
      },

      immediate: true,
    },
  },

  mounted() {
    this.findUsedPricingConfig();
  },

  methods: {
    ...mapMutations([
      'setActualMarketPriceRequest',
      'updatePart',
      'updateSingleProcessChain',
      'updateMarketPricePerPartUserValue',
    ]),

    requestMarketPrice() {
      this.loading = true;
      this.buttonTitle = 'Requesting';
      this.setActualMarketPriceRequest(this.part.part_id);
    },

    updateMargin() {
      if (this.boolProcessChainExists && this.chain.margin_user_value !== null) {
        this.currentMargin = useNumberFormatting(this.chain.margin_user_value * 100, 0).number;
      } else if (this.costingConfig && this.costingConfig.margin_default !== undefined) {
        this.currentMargin = useNumberFormatting(this.costingConfig.margin_default * 100, 0).number;
      }
    },

    findUsedPricingConfig() {
      this.usedPricingConfig = this.pricingOptions.find(config => config.uid === this.chain.pricing_config_id);
    },

    setMargin(margin) {
      // sets the margin for the process chain
      let formData = {
        margin_user_value: margin / 100,
      };

      this.axiosInstance.put(`api/v1/process-chain/${this.processChainId}/`, formData).then(response => {
        // for displaying the actual market price calciulated with set value
        this.updateSingleProcessChain(response.data);
      });
    },

    setMarketPricePerPartUserValue(user_value, field) {
      this.updateMarketPricePerPartUserValue({ uid: this.chain.process_chain_id, value: user_value });

      this.savePart({
        [field]: this.chain.market_price.market_price_pp,
      });
    },

    async savePart(formData) {
      await this.axiosInstance
        .put(`/api/v1/process-chain/${this.processChainId}/`, formData)
        .then(response => {
          this.updateSingleProcessChain(response.data);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-table {
  margin: unset;
}

.price-header {
  font-weight: bold;
}

.right-aligned {
  text-align: right;
}

.grey {
  color: var(--spark-lightgrey);
  width: 35px !important;
}

input[type='range'] {
  z-index: 5;
  position: relative;
  appearance: none;
  background-color: #bdc3c7;
  width: 150px;
  height: 5px;
  border-radius: 5px;
  margin: 0 auto;
  outline: 0;
  opacity: 0.15;

  &:hover {
    height: 5px;
    opacity: 1;
  }

  &:before {
    opacity: 0;
  }
}

label {
  text-align: left;
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  background-color: var(--primary-color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:active {
    transform: scale(1.15);
  }
}

button:disabled {
  opacity: 0.5;

  &:hover {
    color: black;
    cursor: default;
  }
}

input[type='range']::-webkit-slider-thumb:hover {
  background-color: rgb(255, 0, 0);
  border: 2px solid #e74c3c;
}

/* Hide arrows in number input field in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ... and Firefox */
input[type='number'] {
  appearance: textfield;
}
</style>
