<template>
  <div>
    <div class="m-8">{{ title }}</div>
    <table class="w-full">
      <thead>
        <tr class="text-gray-500 text-11 border-b border-gray-100 align-middle h-48 mx-8">
          <th class="pl-8 w-1/4">Supplier</th>
          <th class="w-1/2">Contact</th>
          <th class="w-1/8 text-center">Show in My Suppliers</th>
          <td class="w-1/8">&nbsp;</td>
        </tr>
      </thead>

      <tr
        v-for="supplier in suppliers"
        :key="supplier"
        class="text-11 align-middle h-40 border-b border-gray-100 hover:bg-gray-50"
      >
        <!-- <td>{{ supplier.has_logo }}</td> -->
        <td class="pl-8">{{ supplier.name }}</td>
        <td>
          <div class="flex">
            <div>{{ supplier.sales_contact?.first_name }} {{ supplier.sales_contact?.last_name }}</div>
          </div>
          <div class="text-gray-500">
            {{ supplier.sales_contact?.email }}
          </div>
        </td>
        <td class="text-center">
          <i v-if="checkIfSupplierInMyProduction(supplier.uid)" class="fas fa-check" /><i v-else class="fas fa-times" />
        </td>

        <td>
          <DropdownMenu
            :list-options="dropdownOptions[supplier.uid]"
            title-icon="fas fa-ellipsis-h"
            close-click-style="always"
            @update-option="show($event, supplier)"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import DropdownMenu from '../../../../../components/Reusable/DropdownMenu.vue';

export default {
  name: 'SupplierList',

  components: {
    DropdownMenu,
  },

  props: {
    title: { type: String, default: '' },
    supplierRestriction: { type: String, default: '' },
    suppliers: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['update'],

  data() {
    return { dropdownOptions: {} };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState('supplier', ['users', 'selectedSuppliers', 'selectedSalesRep', 'isSupplier']),

    selectedSuppliersValue: {
      get() {
        return this.selectedSuppliers;
      },

      set(value) {
        this.setSelectedSuppliers(value);
      },
    },
  },

  watch: {
    suppliers: {
      handler() {
        this.calculateDropownOptions();
      },

      deep: true,
    },
  },

  async mounted() {
    await this.fetchSelectedSuppliers(); // Get all supplier
    this.calculateDropownOptions();
  },

  methods: {
    ...mapActions('supplier', ['createEmailSupplier', 'updateSuppliers', 'fetchSelectedSuppliers', 'fetchUsers']),

    ...mapMutations('supplier', ['setSelectedSuppliers']),

    calculateDropownOptions() {
      if (this.supplierRestriction === 'public')
        this.suppliers.forEach(supplier => {
          if (this.checkIfSupplierInMyProduction(supplier.uid)) {
            this.dropdownOptions[supplier.uid] = [
              {
                text: 'Hide in My suppliers',
                value: 'remove',
                icon: 'fas fa-columns',
                disabled: false,
              },
            ];
          } else {
            this.dropdownOptions[supplier.uid] = [
              {
                text: 'Show in my production',
                value: 'add',
                icon: 'fas fa-columns',
                disabled: false,
              },
            ];
          }
        });
      else if (this.supplierRestriction === 'customer') {
        this.suppliers.forEach(supplier => {
          if (this.checkIfSupplierInMyProduction(supplier.uid)) {
            this.dropdownOptions[supplier.uid] = [
              {
                text: 'Delete',
                value: 'remove',
                icon: 'fas fa-trash',
                disabled: false,
              },
            ];
          }
        });
      }
    },

    checkIfSupplierInMyProduction(uid) {
      let isInMyProduction = false;
      Object.keys(this.selectedSuppliersValue).forEach(key => {
        if (uid === this.selectedSuppliers[key].uid) {
          isInMyProduction = true;
        }
      });
      return isInMyProduction;
    },

    show(event, supplier) {
      if (event.value === 'remove') {
        this.removeFromInMyProduction(supplier);
      } else if (event.value === 'add') {
        this.addToInMyProduction(supplier);
      }
    },

    removeFromInMyProduction(supplier) {
      this.selectedSuppliersValue = this.selectedSuppliersValue.filter(function (el) {
        return el.uid != supplier.uid;
      });
      this.updateSupplierList();
      this.calculateDropownOptions();
    },

    addToInMyProduction(supplier) {
      this.selectedSuppliersValue.push(supplier);
      this.setSelectedSuppliers(this.selectedSuppliersValue);
      this.updateSupplierList();
      this.calculateDropownOptions();
    },

    updateSupplierList() {
      this.updateSuppliers({ notify: this.$root.notify });
      location.reload();

      this.$emit('update');
    },
  },
};
</script>
