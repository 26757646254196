<template>
  <div class="process-step min-w-[200px] overflow-hidden">
    <div class="process-step-title-container" :style="{ backgroundColor: color }" :title="step.name">
      <p class="process-step-title-label mr-8">{{ step.name }}</p>
      <SparkButtonIcon
        v-if="userFeedback"
        title="Reset Manual Overwrites"
        icon="fas fa-undo"
        custom="text-white hover:text-gray-200"
        @click="resetFeedbackStep"
      />
      <SparkButtonIcon
        v-if="step.step_type != 'main'"
        custom="text-white hover:text-gray-200"
        title="Delete this step from process chain"
        icon="fas fa-times"
        class="mr-4"
        @click="deleteProcessStep()"
      />
    </div>
    <p v-if="!showWarning | (levelWarningMessage == null)" class="info">
      Calculated on <b>{{ levelOfCalculation }}</b> level
    </p>
    <p v-if="showWarning && levelWarningMessage != null" class="info warning">
      Recommendation:<br />Change on <b>{{ levelWarningMessage }}</b> level.
    </p>
    <table class="w-full">
      <tbody>
        <tr>
          <td class="w-1/3">
            <div>
              <div class="ml-8 text-11">Labor</div>
              <div class="text-gray-500 text-9 ml-8">hh:mm:ss</div>
            </div>
          </td>
          <td class="w-2/3 pr-4">
            <FeedbackValueTimespan
              :feedback-value="laborTime"
              editable
              @feedback-value-changed="setTime($event, 'labor')"
              @input-focus-active="checkValidity($event)"
            />
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div class="ml-8 text-11">Machine</div>
              <div class="text-gray-500 text-9 ml-8">hh:mm:ss</div>
            </div>
          </td>
          <td class="pr-4">
            <FeedbackValueTimespan
              :feedback-value="machineTime"
              editable
              title="Includes setup and unloading."
              @feedback-value-changed="setTime($event, 'mac_t')"
              @input-focus-active="checkValidity($event)"
            />
          </td>
        </tr>

        <tr>
          <td colspan="2">
            <hr />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <FeedbackValue
              :feedback-value="cost"
              field-name="Cost"
              field-unit="€"
              field-type="normal"
              field-style="text-align: right; font-weight: bold;  margin-left:8px"
              :indent-type="0"
              :colspan="2"
              @feedback-value-changed="setCost($event)"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <FeedbackValue
              :feedback-value="leadtime"
              field-name="Leadtime"
              field-unit="d"
              field-type="normal"
              field-style="text-align: right; font-weight: bold; margin-left:8px"
              unit-field-style="margin-right:8px;"
              :indent-type="0"
              :colspan="2"
              @feedback-value-changed="setLeadtime($event)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FeedbackValueTimespan from '../../Reusable/FeedbackValues/FeedbackValueTimespan.vue';
import SparkButtonIcon from '../../SparkComponents/SparkButtonIcon.vue';

import FeedbackValue from '@/components/Reusable/FeedbackValues/FeedbackValue.vue';
import { floatFormat } from '@/helpers.js';

export default {
  name: 'ProcessStep',

  components: { FeedbackValue, SparkButtonIcon, FeedbackValueTimespan },

  props: {
    step: {
      type: Object,
      default() {
        return {};
      },
    },

    level: {
      type: Object,
      default() {
        return {};
      },
    },

    color: { type: String, default: '' },
  },

  data() {
    return {
      cost: 0,
      showWarning: false,
      machineTime: 0,
      laborTime: 0,
      leadtime: 0,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),

    levelWarningMessage() {
      if (this.step.calc_lvl == 'part' && !Object.keys(this.level).includes('_pp')) {
        return 'Part';
      }
      if (this.step.calc_lvl == 'job' && !Object.keys(this.level).includes('_pj')) {
        return 'Job';
      }
      if (this.step.calc_lvl == 'lot' && !Object.keys(this.level).includes('_pl')) {
        return 'Lot';
      } else {
        return null;
      }
    },

    levelOfCalculation() {
      return this.step.calc_lvl;
    },

    userFeedback() {
      if (this.step?.overwrites != undefined) {
        return Object.keys(this.step.overwrites) != 0;
      } else {
        return false;
      }
    },

    processStepUid() {
      return this.step.process_step_id;
    },
  },

  watch: {
    step() {
      this.calculate();
    },

    level() {
      this.calculate();
    },
  },

  mounted() {
    this.calculate();
  },

  methods: {
    ...mapMutations(['updatePart']),

    roundValue(number, digit = 2) {
      return parseFloat(floatFormat(number, digit));
    },

    checkValidity(event) {
      this.showWarning = event;
    },

    calculate() {
      this.cost = this.roundValue(this.step.cost['cost' + Object.keys(this.level)[0]]).toFixed(2);
      this.laborTime = this.step.labor['labor' + Object.keys(this.level)[0]];
      this.machineTime = this.step.mac_t['mac_t' + Object.keys(this.level)[0]];
      this.leadtime = this.step.leadtime;
    },

    async setCost(value) {
      let formData = {
        ['cost' + Object.keys(this.level)[0]]: value,
      };
      this.updateProcessStepFeedback(formData);
    },

    async setTime(value, timefield) {
      // [timefield + this.selection] --> 'labor_pl', 'labor_pj'...
      let formData = {
        [timefield + Object.keys(this.level)[0]]: value,
      };
      this.updateProcessStepFeedback(formData);
    },

    async setLeadtime(value) {
      let formData = { leadtime: value };
      this.updateProcessStepFeedback(formData);
    },

    async resetFeedbackStep() {
      let formData = new FormData();
      formData.append('reset_feedback_step', true);
      this.updateProcessStepFeedback(formData);
    },

    async updateProcessStepFeedback(formData) {
      this.axiosInstance.put(`/api/v1/process-step-feedback/${this.processStepUid}/`, formData).catch(error => {
        this.$root.notifyError('Editing Process Step', error);
      });
    },

    async deleteProcessStep() {
      if (this.step.step_type != 'main') {
        let formData = new FormData();
        formData.append('process_step_id', this.processStepUid);

        this.axiosInstance
          .delete(`/api/v1/process-step/`, { data: formData })
          .then(response => {
            this.updatePart(response.data);
          })
          .catch(error => {
            this.$root.notifyError('Deleting Process Step', error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}

.warning {
  margin: 0px 3px;
  font-size: var(--9px);
  height: 30px;
  background-color: var(--spark-delete);
  color: black;
  height: 30px;
  margin-bottom: 5px;
  border-radius: 2px;
}

.info {
  margin: 0px 3px;
  font-size: var(--9px);
  height: 30px;
  margin-bottom: 5px;
  padding: 0px 5px;
}
</style>
