<template>
  <div class="flex flex-col bg-white border border-gray-100 rounded-8 mt-16">
    <div class="p-20">
      <div>Process Chain</div>
      <div class="text-11 text-gray-400 mb-16">Select process chain you want to calibrate</div>
      <SparkInput
        v-model="selectedProcessChain.name"
        name="template"
        label="Process Chain"
        placeholder="Select..."
        @click="openProcessChainTemplateModal()"
      />
      <div v-if="Object.keys(selectedProcessChain).length != 0" class="text-13">
        <div class="flex">
          <div class="text-gray-400 ml-4">Process</div>
          <div class="mr-8">&nbsp;{{ selectedProcessChain.prc_name }}</div>
        </div>
        <div class="flex">
          <div class="text-gray-400 ml-4">Material</div>
          <div class="mr-8">&nbsp;{{ selectedProcessChain.material_name }}</div>
        </div>
        <div class="flex">
          <div class="text-gray-400 ml-4">Machine</div>
          <div>&nbsp;{{ selectedProcessChain.machine }}</div>
        </div>
      </div>
    </div>
    <hr />
    <div class="flex p-20 flex-col">
      <div class="flex justify-between">
        <div>
          <div>Parts</div>
          <div class="text-11 text-gray-400">Selected parts for calibration</div>
        </div>
      </div>
      <div class="border border-gray-50 rounded-4 mt-8 flex-grow text-13">
        <table class="w-full">
          <tr
            v-for="(part, index) in transformedPartsData"
            :key="part.part_id"
            class="border border-gray-50 items-center hover:bg-gray-50"
          >
            <td class="pl-4">{{ part.part_name }}</td>
            <td class="pl-16 text-gray-500">Lot size</td>
            <td><SparkInput v-model="part.lot_size" class="w-100" name="lot_size" @change="setPartData(part)" /></td>
            <td v-if="selectedMode === 'machine'" class="pl-16 text-gray-500">Build time</td>
            <td v-if="selectedMode === 'machine'">
              <FeedbackValueTimespan
                :feedback-value="part.value"
                editable
                @feedback-value-changed="setTime($event, part)"
              />
            </td>
            <td v-if="selectedMode === 'price'" class="pl-16 text-gray-500">Price</td>
            <td class="flex items-center gap-4">
              <SparkInput
                v-if="selectedMode === 'price'"
                v-model="part.value"
                class="w-100"
                name="price"
                @change="setPartData(part)"
              />
              <div v-if="selectedMode === 'price'" class="text-gray-500">€</div>
            </td>

            <td class="pl-16 pr-4">
              <DropdownMenu
                class="border border-gray-200 rounded-4"
                :list-options="options"
                title-icon="fas fa-ellipsis-h"
                close-click-style="always"
                @update-option="tableAction($event, part, index)"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <SelectTemplateModal :show="showModal" @close="closeSettingsModal()" />

    <DuplicatePartModal :show="showDuplicateModal" :part-to-duplicate="partToDuplicate" @close="closeModal()" />
    <DeletePartModal
      :show="showDeleteModal"
      :index-to-delete="indexToDelete"
      :part-to-delete="partToDelete"
      @close="closeModal()"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import DropdownMenu from '../../../components/Reusable/DropdownMenu.vue';
import FeedbackValueTimespan from '../../../components/Reusable/FeedbackValues/FeedbackValueTimespan.vue';

import DeletePartModal from './DeletePartModal.vue';
import DuplicatePartModal from './DuplicatePartModal.vue';
import SelectTemplateModal from './SelectTemplateModal.vue';

import SparkInput from '@/components/SparkComponents/SparkInput.vue';

export default {
  name: 'SelectTemplate',

  components: {
    SparkInput,
    SelectTemplateModal,
    DropdownMenu,
    FeedbackValueTimespan,
    DuplicatePartModal,
    DeletePartModal,
  },

  data() {
    return {
      showModal: false,
      options: [
        { text: 'Duplicate', value: 'duplicate', icon: 'fas fa-copy', disabled: false },
        { text: 'Delete', value: 'delete', icon: 'fas fa-trash', disabled: false },
      ],

      showDuplicateModal: false,
      showDeleteModal: false,
      partToDuplicate: {},
      partToDelete: {},
      indexToDelete: '',
    };
  },

  computed: {
    ...mapState('calibration', ['transformedPartsData', 'selectedProcessChain', 'selectedMode', 'instancesCreated']),
    ...mapState('application', ['axiosInstance']),
  },

  methods: {
    ...mapMutations('calibration', ['setNewTransformedPartData', 'setTransformedPartsData']),
    openProcessChainTemplateModal() {
      this.showModal = true;
    },

    closeSettingsModal() {
      this.showModal = false;
    },

    closeModal() {
      this.showDuplicateModal = false;
      this.showDeleteModal = false;
    },

    tableAction(event, part, index) {
      if (event.value === 'duplicate') {
        this.showDuplicateModal = true;
        this.partToDuplicate = part;
      }
      if (event.value === 'delete') {
        this.showDeleteModal = true;
        this.partToDelete = part;
        console.log(index);
        this.indexToDelete = index;
      }
    },

    setTime(time, part) {
      part['value'] = time;
      this.setNewTransformedPartData(part);
    },

    setPartData(part) {
      this.setNewTransformedPartData(part);
    },
  },
};
</script>
