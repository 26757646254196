<template>
  <div id="wrapper" class="app grid">
    <Styling />
    <Websocket />
    <DownloadManager />
    <Popup />
    <Navigation />
    <div class="relative top-56">
      <router-view />
    </div>
  </div>
</template>

<script>
import { productFruits } from 'product-fruits';
import _refiner from 'refiner-js';
import { mapState, mapActions, mapMutations } from 'vuex';

import DownloadManager from './components/General/DownloadManager.vue';
import Navigation from './components/General/Navigation.vue';
import Styling from './components/General/Styling.vue';
import Popup from './components/Reusable/Popup.vue';
import Websocket from './components/Reusable/Websocket.vue';

import { createAxiosInstance } from '@/axiosInstance';

export default {
  name: 'App',

  components: { Styling, Websocket, Popup, Navigation, DownloadManager },

  computed: {
    ...mapState(['productFruitsInitialized', 'user']),

    axiosInstance() {
      return this.$store.state.application.axiosInstance;
    },
  },

  watch: {
    user: {
      handler() {
        if (this.user?.product_fruits_id && !this.productFruitsInitialized) {
          try {
            productFruits.init('iSCDXXZ1MozeqvGj', 'en', {
              username: this.user.product_fruits_id,
              role: this.$store.state.user.user_role,
            });
            this.setProductFruitsInitialized();
          } catch {
            console.debug('product fruits couldm not be initialized');
          }
        }
        this.getRolesIfUserManager();
        //initialize supplier module
        this.init();
      },

      immediate: true,
    },
  },

  beforeCreate() {
    /*
    Initializa App Store and set token
    */
    this.$store.dispatch('initializeStore');
    // Create AxiosInstance
    const { axiosInstance, axiosInstanceFileUpload } = createAxiosInstance(this.$keycloak); // Here we initialize the axiosInstance
    this.$store.commit('application/setAxiosInstances', { axiosInstance, axiosInstanceFileUpload }, { root: true }); // Putting axiosInstances into the store
  },

  mounted() {
    // get the user so that user / organization settings can be used in the whole app
    this.$getUser(this.$keycloak, this.axiosInstance, this.$store);

    this.fetchPipelineStatuses();
    this.fetchPricingOptionList();
    this.fetchAnalysisProfileList();
    this.getChoiceLists();
    this.getAllMachines();
  },

  created() {
    _refiner('setProject', '232241f0-2f83-11ee-ac21-49ac5fe15bbe');
  },

  methods: {
    ...mapMutations([
      'setChoiceLists',
      'setProductFruitsInitialized',
      'setAvailableRoles',
      'updateAllMachines',
      'updateFilteredMachines',
    ]),

    ...mapActions('supplier', ['init']),

    ...mapActions(['fetchPipelineStatuses', 'fetchPricingOptionList', 'fetchAnalysisProfileList']),

    notify(theme, title, message, duration) {
      /*
      Show a notification. Available themes: 'success', 'info', 'warning', 'error' accessable from all components via this.$root.notify
      */
      window.createNotification({
        closeOnClick: true,
        displayCloseButton: false,
        positionClass: 'nfc-bottom-right',
        showDuration: duration,
        theme: theme,
      })({
        title: title,
        message: message,
      });

      // Create a timestamp and save it as a readable version
      let dateString = this.$dayjs().format('HH:mm:ss');

      // Save the notification to the store, so that session notifications can be reviewed by users.
      let notification = {
        title: title.split('undefined')[0],
        message: typeof message === 'string' ? message.split('undefined')[0] : '',
        theme: theme,
        timestamp: dateString,
      };
      this.$store.commit('updateUserNotifications', notification);
    },

    async notifyError(messageHeader, error) {
      var error_message = '';
      if (error.response.data instanceof Blob) {
        error_message = await this.handleExpectedFileResponse(error);
      } else if (error.response.data instanceof Object) {
        error_message = error.response.data.error_message;
      }
      this.notify('error', messageHeader, error_message, 3000);
    },

    async handleExpectedFileResponse(error) {
      const text = await error.response.data.text();
      const errorData = JSON.parse(text);
      return errorData.error_message;
    },

    async getChoiceLists() {
      this.axiosInstance
        .get('/api/v1/choice-lists/')
        .then(response => {
          return response.data;
        })
        .then(data => {
          this.setChoiceLists(data);
        })
        .catch(error => {
          console.log(error);
        });
    },

    async getRolesIfUserManager() {
      if (this.user.is_usermanager) {
        this.axiosInstance
          .get('api/v1/user-management/get-available-roles/')
          .then(response => {
            this.setAvailableRoles(response.data.roles);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },

    getAllMachines() {
      this.axiosInstance.get('api/v1/machines-all/').then(response => {
        let allMachines = response.data;
        let filteredMachines = Object.keys(allMachines).map(key => {
          return {
            key: Number(key),
            value: allMachines[key].mac_id,
            text: allMachines[key].mac_name + ' | ' + allMachines[key].oem_name,
            prc_uid: allMachines[key].prc_uid,
          };
        });
        filteredMachines = { ...filteredMachines };
        this.updateAllMachines(filteredMachines);
        this.updateFilteredMachines(filteredMachines);
      });
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('@/fonts/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
}
</style>
