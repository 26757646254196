<template>
  <SparkModal :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-19 font-bold" v-text="'Create Supplier'" />
    </template>

    <template #default>
      <SparkInput v-model="supplierName" class="mb-16" name="supplier_name" label="Name" />
      <SparkInput v-model="supplierEmail" name="supplier_email" label="Email" />
      <label class="text-13 text-gray-500" for="file_input">Logo</label>
      <input id="file_input" type="file" accept=".jpeg, .jpg, .png, .svg" @change="handleFileUpload" />
      <div class="flex justify-end gap-16">
        <SparkButton variant="outlined" @click="close">
          <div v-text="'Cancel'" />
        </SparkButton>
        <SparkButton variant="secondary" @click="createSupplier">
          <div v-text="'Create Email Supplier'" />
        </SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapActions } from 'vuex';

import SparkButton from '../../../../../components/SparkComponents/SparkButton.vue';
import SparkInput from '../../../../../components/SparkComponents/SparkInput.vue';
import SparkModal from '../../../../../components/SparkComponents/SparkModal.vue';

export default {
  name: 'CreateSupplierModal',

  components: {
    SparkButton,
    SparkModal,
    SparkInput,
  },

  props: {
    show: { type: Boolean },
  },

  emits: ['close'],

  data() {
    return {
      supplierName: '',
      supplierEmail: '',
      logoFile: null,
    };
  },

  methods: {
    ...mapActions('supplier', ['createEmailSupplier', 'updateSuppliers']),

    close() {
      this.$emit('close');
    },

    async createSupplier() {
      let formData = new FormData();

      if (this.supplierName) {
        formData.append('name', this.supplierName);
      }

      if (this.supplierEmail) {
        formData.append('email', this.supplierEmail);
      }

      if (this.logoFile) {
        formData.append('file', this.logoFile);
      }
      this.createEmailSupplier({ data: formData, notify: this.$root.notify });
      this.close();
      this.emptyForm();
      this.updateSuppliers();

      this.supplierName = '';
      this.supplierEmail = '';
      this.logoFile = null;
      location.reload();
    },

    handleFileUpload(event) {
      this.logoFile = event.target.files[0];
    },

    emptyForm() {
      this.supplierName = '';
      this.supplierEmail = '';
      this.logoFile = null;
    },
  },
};
</script>
