<template>
  <div>
    <SparkForm class="w-200 ml-10">
      <SparkInput
        :model-value="currentMaterial"
        name="input"
        label="Current Material"
        :title="lockedForUser ? lockedTitle : ''"
        animate-label
        @input="searchMaterials"
        @focus="showSuggestions = true"
        @blur="hideSuggestions"
        @keydown.down.prevent="moveDown"
        @keydown.up.prevent="moveUp"
        @keydown.enter.prevent="selectSuggestion"
      />
    </SparkForm>
    <div class="ml-10">
      <MaterialSuggestions
        :show-suggestions="showSuggestions"
        :filtered-materials="filteredMaterials"
        :selected-index="selectedIndex"
        @select-suggestion="selectSuggestion"
      />
    </div>
    <span v-if="currentMaterial && currentMaterial !== ''" class="text-11 ml-10 p-2">
      <i class="fas fa-check mr-4" />Material Proposals are shown at the next page.
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import SparkForm from '../../SparkComponents/SparkForm.vue';
import SparkInput from '../../SparkComponents/SparkInput.vue';

import MaterialSuggestions from './MaterialSuggestions.vue';

export default {
  name: 'CurrentMaterialSelection',

  components: { SparkForm, SparkInput, MaterialSuggestions },

  emits: ['material-change'],

  data() {
    return {
      materials: [],
      showSuggestions: false,
      selectedIndex: -1,
      searchString: '',
    };
  },

  computed: {
    ...mapState(['part', 'lockedTitle']),
    ...mapState('application', ['axiosInstance']),
    ...mapGetters(['lockedForUser']),

    currentMaterial: {
      get() {
        return this.part.current_mat;
      },

      set(value) {
        this.setCurrentMat(value);
        this.$emit('material-change', { current_mat: value });
      },
    },

    filteredMaterials() {
      const searchTerm = (this.searchString || '').toLowerCase();
      return this.materials.filter(material =>
        material.toLowerCase().includes(searchTerm)
      );
    },
  },

  watch: {
    'part.current_mat': {
      immediate: true,
      handler(newVal) {
        this.searchString = newVal || '';
      },
    },
  },

  methods: {
    ...mapMutations(['setCurrentMat']),

    async searchMaterials(event) {
      this.searchString = event.target.value;
      this.currentMaterial = this.searchString;
      if (this.searchString.trim() === '') {
        this.materials = [];
        this.showSuggestions = false;
        return;
      }

      let formData = {
        search_string: this.searchString,
      };
      try {
        const response = await this.axiosInstance.post(`api/v1/material-search/`, formData);
        this.materials = response.data.map(item => item.mat_name);
        this.showSuggestions = true;
      } catch (error) {
        console.error('Error searching materials:', error);
        this.materials = [];
        this.showSuggestions = false;
      }
    },

    hideSuggestions() {
      setTimeout(() => {
        this.showSuggestions = false;
      }, 200);
    },

    moveDown() {
      if (this.selectedIndex < this.filteredMaterials.length - 1) {
        this.selectedIndex += 1;
      }
    },

    moveUp() {
      if (this.selectedIndex > 0) {
        this.selectedIndex -= 1;
      }
    },

    selectSuggestion(suggestion) {
      if (suggestion) {
        this.searchString = suggestion;
      } else if (this.selectedIndex !== -1) {
        this.searchString = this.filteredMaterials[this.selectedIndex];
      }
      this.currentMaterial = this.searchString;
      this.showSuggestions = false;
    },
  },
};
</script>