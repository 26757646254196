<template>
  <div class="flex flex-column mr-20 ml-20">
    <div>
      Go to the
      <span class="font-bold cursor-pointer" @click="gotoLibrary"
        ><i class="fas fa-arrow-right" /> process step library</span
      >
      to create new process steps
    </div>
    <div class="flex flex-row flex-wrap">
      <div v-for="template in filteredProcessStepTemplatess" :key="template">
        <div class="process-step">
          <div
            class="process-step-title-container"
            :style="[{ backgroundColor: additionalData.color }]"
            :title="template.name"
            :class="addedProcessStepTemplates.includes(template.uid) ? 'opacity-70' : ''"
          >
            <p class="process-step-title-label">
              {{ template.name }}
            </p>
            <button
              v-if="!addedProcessStepTemplates.includes(template.uid)"
              type="button"
              class="spark-btn"
              title="Add"
              @click="addProcessStep(template.uid)"
            >
              <i class="fas fa-plus" />
            </button>
            <div v-else class="flex mx-2 items-center"><i class="fas fa-check" />Added</div>
          </div>

          <div class="spark-table">
            <tbody>
              <tr>
                <th>Restriction</th>
                <td v-if="template.restriction == 'organization'"><i class="fas fa-user-friends" /></td>
                <td v-else-if="template.restriction == 'owner'"><i class="fas fa-user" /></td>
                <td v-else>-</td>
                <td />
              </tr>
              <tr>
                <th>Calculation Level</th>
                <td>{{ getReadableCalcLvl(template.calc_lvl) }}</td>
                <td />
              </tr>
            </tbody>
            <tbody v-if="template.calc_lvl == 'expression'">
              <tr>
                <th>Expression</th>
                <td class="right">{{ template.calc_expression }}</td>
                <td />
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th>Setup Time (hh:mm:ss)</th>
                <td><FeedbackValueTimespan :editable="false" :feedback-value="template?.setup_time" /></td>
                <td />
              </tr>
              <tr>
                <th>Process Time (hh:mm:ss)</th>
                <td><FeedbackValueTimespan :editable="false" :feedback-value="template?.process_time" /></td>
                <td />
              </tr>
              <tr>
                <th>Unloading Time (hh:mm:ss)</th>
                <td><FeedbackValueTimespan :editable="false" :feedback-value="template?.unloading_time" /></td>
                <td />
              </tr>

              <tr>
                <th>Fixed Cost</th>
                <td class="right">{{ template.fixed_cost }}</td>
                <td>€</td>
              </tr>
              <tr>
                <th>Machine Hourly Rate</th>
                <td class="right">{{ template.machine_hourly_rate }}</td>
                <td>€/h</td>
              </tr>
              <tr>
                <th>Staff Time Factor</th>
                <td class="right">{{ $formatZeroDecimalPlaces(template.staff_time_factor * 100) }}</td>
                <td>%</td>
              </tr>
              <tr>
                <th>Worker</th>
                <td colspan="2">{{ getReadableWorkerType(template.worker_type) }}</td>
              </tr>
            </tbody>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FeedbackValueTimespan from '../../Reusable/FeedbackValues/FeedbackValueTimespan.vue';

import useCopyObject from '@/composables/copyObject.js';

export default {
  name: 'ProcessStepDialog',

  components: { FeedbackValueTimespan },

  props: {
    additionalData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      filteredProcessStepTemplatess: {},
      addedProcessStepTemplates: [],
    };
  },

  computed: {
    ...mapState(['workerTypes', 'calcLvls', 'user']),
    ...mapState('application', ['axiosInstance']),

    stepType() {
      return this.additionalData.filterDialog.toLowerCase().split('-')[0];
    },
  },

  watch: {
    additionalData() {
      this.filteredProcessStepTemplatess = {};
      this.getProcessStepTemplates();
    },
  },

  mounted() {
    this.getProcessStepTemplates();
  },

  methods: {
    ...mapMutations(['updatePart', 'triggerPopup']),

    gotoLibrary() {
      this.$router.push({ name: 'process-step-library' });
      this.triggerPopup('');
    },

    getReadableCalcLvl(calcLvl) {
      return Object.values(this.calcLvls).find(obj => {
        return obj.value === calcLvl;
      }).text;
    },

    getReadableWorkerType(workerType) {
      return Object.values(this.workerTypes).find(obj => {
        return obj.value === workerType;
      }).text;
    },

    filterByStepType(data) {
      return Object.fromEntries(
        Object.entries(data).filter(
          ([key, step]) =>
            step.step_type == this.stepType &&
            (step.prc_acronym == this.additionalData.chain.prc_acronym) | (step.prc_acronym == null)
        )
      );
    },

    checkIfTemplatesAlreadyInProcessChainAdded(steps) {
      let array = [];
      Object.keys(steps).forEach(id => {
        array.push(steps[id].process_step_template_id);
      });
      return array;
    },

    async getProcessStepTemplates() {
      this.axiosInstance
        .get(`/api/v1/process-step-templates-all/`)
        .then(response => {
          let allProcessSteps = this.filterByStepType(response.data);
          this.filteredProcessStepTemplatess = useCopyObject(allProcessSteps);
          this.addedProcessStepTemplates = this.checkIfTemplatesAlreadyInProcessChainAdded(
            this.additionalData.chain.process_steps
          );
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },

    addProcessStep(uid) {
      let formData = new FormData();
      formData.append('process_step_template_uid', uid);
      formData.append('process_chain_id', this.additionalData.chain.process_chain_id);

      this.axiosInstance
        .post(`/api/v1/process-step/`, formData)
        .then(response => {
          this.updatePart(response.data);
          this.addedProcessStepTemplates.push(uid);
        })
        .catch(error => {
          this.$root.notifyError('Adding Process Step', error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.added-process-step {
  font-weight: bold;
}

.right {
  text-align: right;
}

.spark-window-titlebar {
  margin: unset;
  padding: 20px;
  font-size: var(--11px);
}
</style>
