const calibrationMutations = {
  setSelectedParts(state, parts) {
    state.selectedParts = parts;

    let transformed = [];
    Object.keys(parts).forEach(key => {
      transformed.push({
        part_name: parts[key].basename,
        part_id: parts[key].part_id,
        lot_size: parts[key].primary_lot_size,
        value: null,
        uid: null,
      });
    });

    state.transformedPartsData = transformed;
  },

  setTransformedPartsData(state, data) {
    state.transformedPartsData = data;
  },

  setSelectedMode(state, mode) {
    state.selectedMode = mode;
  },

  setActualPage(state, page) {
    state.actualPage = page;
  },

  setSelectedProcessChain(state, chain) {
    state.selectedProcessChain = chain;
  },
  setCalibrationConfigUid(state, uid) {
    state.calibrationConfigUid = uid;
  },
  setNewTransformedPartData(state, part) {
    state.transformedPartsData[part.uid] = part;
  },

  deletePartFromTransformedPartData(state, index) {
    state.transformedPartsData.splice(index, 1);
  },

  duplicatePartFromTransformedPartData(state, part) {
    state.transformedPartsData.push(part);
  },

  resetCalibrationState(state) {
    state.selectedParts = {};
    state.selectedMode = 'unset';
    state.actualPage = 'CalibrationMode';
    state.transformedPartsData = {};
    state.selectedProcessChain = {};
    state.calibrationConfigUid = '';
    state.calibrationResult = {};
    state.calibrationInstancesResult = {};
    state.instancesCreated = false;
    state.loadCalibration = false;
  },

  setCalibrationResult(state, data) {
    state.calibrationResult = data;
  },
  setCalibrationInstancesResult(state, data) {
    state.calibrationInstancesResult = data;
  },

  setSelectedParameters(state, data) {
    state.selectedParameters = data;
  },

  setInstancesCreated(state, data) {
    state.instancesCreated = data;
  },

  loadExistingCalibration(state, data) {
    state.loadCalibration = data;
  },
};

export default calibrationMutations;
